<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <!-- <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2> -->
    </b-link>

    <div class="container mt-5 pt-3 mx-md-5">
      <!--  <b-card> -->
      <!-- form -->
      <b-form @submit.prevent>
        <div class="w-100 text-center">
          <b-row>
            <b-col cols="12">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <span
                    ><strong>Hello Esteemed Customer!</strong><br />
                    Your dispatch request has been cancelled.</span
                  >
                  <br />
                  <br />
                  <span>Thank you for using our service.</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </div>
      </b-form>
      <!-- </b-card> -->
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BCard, BRow, BCol, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BCard,
    /* BImg,
    BCardBody, */
    BRow,
    BCol,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },

  mounted() {},
  created() {
    this.cancelled();
  },
  destroyed() {},
  methods: {
    // confirm texrt
    cancelled() {
      this.$swal({
        icon: "success",
        title: "Cancelled!",
        text: "Your dispatch request has been cancelled.",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
